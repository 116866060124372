.file-upload {
  &-wrapper {
    width: 600px;
    height: 372px;
    padding: 24px 70px;
    @apply mx-auto;
  }
  &-container {
    @apply w-full;
    padding: 28px 28px 36px;
    background: rgba(255, 255, 255, 0.71);
    border: 1px dashed #595fed;
    border-radius: 10px;
    .file-name-text {
      @apply text-text-primary;
      font-size: 15px;
      padding: 7px 10px;
    }
    .icon-folder {
      margin: 10px 10px;
    }
  }
  &__input-container {
    height: 39px;
    background: #f1f6fa;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
  }

  &__input-field {
    @apply hidden;
  }
  &--csv {
    .file-upload__input-container {
      @apply border border-primary-brand;
      box-shadow: 0px 4px 4px #dee3e8;
      background-color: #fff;
    }
    .file-name-text.active {
      background-color: rgba(139, 210, 189, 0.58);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
