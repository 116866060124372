.main {
    height: calc(100vh - 56px);
}
.avatar {
    &-container {
        width: 66px;
        height: 38px;
        @apply flex items-center justify-center bg-white cursor-pointer;
        border: 1px solid #b7c6d5;
        border-radius: 6px;
    }
    &-wrapper {
        @apply flex items-center justify-center rounded-full overflow-hidden bg-grey-400;
    }
    &__pic {
        @apply w-full h-full object-cover overflow-hidden rounded-full bg-grey-400;
    }
}
.sigPad {
    width: 100%;
    height: 220px;
}
.error-text {
    @apply text-xs text-red;
}

.bg-shadow-blur {
    @apply relative;
    &::after {
        content: "";
        @apply w-full h-full absolute top-0 left-0;
        background: #c2cddc;
        opacity: 0.3;
        filter: blur(100px);
        z-index: -1;
    }
    &--high {
        opacity: 0.6;
    }
}

ul,
li {
    list-style: none;
}

.text-base-1 {
    font-size: 15px;
}
.text-22 {
    font-size: 22px;
}
.text-20 {
    font-size: 20px;
}
.text-13 {
    font-size: 13px;
}
.text-16 {
    font-size: 16px;
}
.text-18 {
    font-size: 18px;
}
.text-11 {
    font-size: 11px;
}
.text-12 {
    font-size: 12px;
}
.text-sm {
    font-size: 14px;
}
.text .card-item {
    background: #ffffff;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
    &--no-border {
        border: 0;
    }
}
.text-secondary {
    color: #777b7e;
}
div[data-tooltip],
.drag-and-drop-comp-list[data-tooltip] {
    &:after {
        content: attr(data-tooltip);
        width: max-content;
        position: absolute;
        @apply bg-black text-white text-13 font-medium rounded opacity-0 invisible;
        padding: 3px 10px;
        top: -30px;
        // transform: translateY(-100%);
        transition: all 300ms;
        z-index: 10;
    }
    &::before {
        content: "";
        @apply absolute w-0 h-0 bg-transparent p-0 opacity-0 invisible;
        border: 8px solid transparent;
        border-top-color: #293846;
        top: initial;
        bottom: 12px;
        left: 3px;
        right: 0;
        z-index: 1;
        transition: all 300ms;
    }
    &:hover:after,
    &:hover::before {
        @apply opacity-100 visible;
        // transform: translateY(0);
    }
    &.right[data-tooltip] {
        &::after {
            top: initial;
            left: calc(100% + 8px);
        }
        &::before {
            z-index: 1;
            left: 100%;
            transform: rotate(90deg);
            transform-origin: top;
        }
    }
    &.tooltip-pos-left[data-tooltip] {
        &::after {
            top: 0;
            right: calc(100% + 8px);
        }
        &::before {
            top: 5px;
            transform: rotate(-90deg) !important;
            right: initial;
            left: -8px;
            z-index: 1;
        }
    }
    &.tooltip-pos-bottom[data-tooltip] {
        &::after {
            top: 23px;
            // left: calc();
            margin-left: 0px;
        }
        &::before {
            top: 9px;
            transform: rotate(-180deg) !important;
            right: initial;
            left: 0px;
            z-index: 1;
        }
    }
    &.tooltip-docpos-bottom[data-tooltip] {
        &::after {
            top: 42px;
            // left: calc();
            margin-left: -130px;
        }
        &::before {
            top: 27px;
            transform: rotate(-180deg) !important;
            right: initial;
            left: 40px;
            z-index: 1;
        }
    }
    &.tooltip-doctop-bottom[data-tooltip] {
        &::after {
            top: -30px;
            // left: calc();
            margin-left: -85px;
        }
        &::before {
            top: -5px;
            transform: rotate(-360deg) !important;
            right: initial;
            left: 0px;
            z-index: 1;
        }
    }
    &.tooltip-dwnload-top[data-tooltip] {
        &::after {
            top: -30px;
            // left: calc();
            margin-left: -106px;
        }
        &::before {
            top: -5px;
            transform: rotate(-360deg) !important;
            right: initial;
            left: 5px;
            z-index: 1;
        }
    }
}
.tooltip-grid-view {
    &::after {
        top: 50% !important;
        transform: translate(-10px, -50%);
        right: 100% !important;
    }
    &::before {
        transform: rotate(-90deg) !important;
        top: 1px !important;
        left: -10px !important;
    }
}
.access-code-icon-wrapper[data-tooltip] {
    &::after {
        top: -4px !important;
    }
    &::before {
        top: 8px;
    }
}

.w-initial {
    width: initial;
}

.signee-list {
    @apply absolute bg-white;
    width: 150px;
    border: 1px solid #bcc8d2;
    border-radius: 10px;
    z-index: 10;
    transform: translateY(20%);
    transition: all 0.3s;
    &__list {
        @apply flex items-center p-2;
        transition: all 300ms ease-in;
    }
    &__header {
        @apply p-2;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #f4f6f8;
    }
    &--sm {
        .signee-list__list {
            padding: 5px 8px;
            @apply text-sm;
            line-height: unset;
            &.text-base-1 {
                font-size: 15px !important;
            }
        }
    }
}

.signee-list {
    &--default {
        .signee-list__list:hover {
            @apply text-primary-brand;
            box-shadow: none;
            background: rgba(89, 95, 237, 0.22);
            transition: all 300ms ease-in;
        }
    }
}

.tooltip-plain {
    @apply absolute text-13 font-medium rounded;
    width: max-content;
    top: -31px;
    color: #000;
    padding: 2px 6px;
    border: 1px solid #4741dc;
    background: #e0dff9;
    z-index: 20;
}

.tooltip-plain-prepare {
    @apply absolute text-13 font-medium rounded;
    width: max-content;
    top: -36px;
    color: #ffffff;
    padding: 6px 8px;
    border: 1px solid #474747;
    background: #474747;
    z-index: 30;
    left: 0;
    display: none;
}

.prepare-tooltip {
    &:hover {
        .tooltip-plain-prepare {
            display: block;
        }
    }
}

// .tooltip-bottom {
//     @apply absolute text-13 font-medium rounded;
//     width: max-content;
//     top: 31px;
//     color: #000;
//     padding: 2px 6px;
//     border: 1px solid #4741dc;
//     background: #e0dff9;
//     z-index: 20;
// }

.btn-ribbon {
    position: absolute;
    left: -10px;
    top: 20px;
    width: 21px !important;
    height: 14px !important;
    font-size: 14px;
    background-color: #a6a9f3;
    border: 1px solid #595fed;
    border-right: 0;
    border-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    z-index: 99;
    cursor: pointer;
    &::after,
    &::before {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        background-color: transparent;
        padding: 0;
        border: 7px solid transparent;
        border-left-color: #a6a9f3;
        top: -1px;
        right: -13px;
        z-index: -1;
    }
    &::before {
        content: "";
        right: -14px;
        border-left-color: #595fed;
        top: -1px;
    }
}
.required:after {
    content: " *";
    color: red;
}

.team-permissions-list {
    @apply border-b pb-2 mb-2;
    border-color: rgba(123, 141, 158, 0.3);
}

.red-border {
    border: 3px dotted rgba(235, 87, 87, 0.8) !important;
}

.scroll-0 {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
    }
}
input[type="radio"] {
    accent-color: #4146d1;
}

.upload-import-workflow {
    padding: 60px 20px;
    background: rgba(255, 255, 255, 0.71);
    border: 1px dashed #595fed;
    border-radius: 10px;
}

.import-workflow-modal {
    padding: 44px;
    width: 950px;
    height: 690px;
    background: #ffffff;
    border-radius: 10px;
    .nav-list {
        &__item {
            font-size: 15px;
            padding: 10px;
            background: #ffffff;
            box-shadow: inset 0px -1px 0px rgba(184, 202, 225, 0.3);
            &.active {
                @apply text-white;
                background: #595fed;
                box-shadow: inset 0px -1px 0px rgba(184, 202, 225, 0.3);
                border-radius: 7px;
            }
        }
    }
    &__right {
        padding: 36px;
        background: #f8fbff;
        border: 1px solid #ebe3e3;
        border-radius: 10px;
    }
}

.receiver-datepicker {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        height: 100%;
    }
}

.txt:hover {
    text-decoration: underline;
}

.b-base {
    border-bottom: 0.1px solid #95a5b5;
}

.text-green {
    color: #10a711;
}

.text-brown {
    color: #654343;
}

.finish-doc-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: justify;
    color: #5f758a;
}
.expire-date-color {
    @apply text-primary-brand;
}
.spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #595fed;
    border-radius: 50%;
    opacity: 0;
    width: 30px;
    height: 30px;
    animation: spin 1s ease-in-out infinite;
}

.folder-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item {
    display: flex;
    &:hover {
        background: #d3dce340;
        transition: all 300ms ease-in;
        border-radius: 8px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sent_icon {
    &:hover {
        path {
            stroke: #595fed;
        }
    }
    &:active {
        path {
            stroke: #595fed;
        }
    }
}

.chrome-picker {
    position: relative;
    left: 80px;
    top: 165px;
    z-index: 100;
}

@media only screen and (max-width: 1440px) {
    .people_text {
        text-align: right;
        padding-right: 54px !important;
    }
}
@media only screen and (min-width: 1500px) {
    .people_text {
        text-align: center;
        padding-right: 135px !important;
    }
}
@media only screen and (max-width: 1440px) {
    .csvPreview {
        margin-left: 15rem;
    }
}
@media only screen and (min-width: 1500px) {
    .csvPreview {
        margin-left: 28rem;
    }
}
@media only screen and (max-width: 1440px) {
    .reuploadCsv {
        margin-right: 16rem;
    }
}
@media only screen and (min-width: 1500px) {
    .reuploadCsv {
        margin-right: 28rem;
    }
}

.bouncing-loader {
    display: flex;
    justify-content: center;
}

.bouncing-loader > div {
    @apply bg-primary;
    width: 8px;
    height: 8px;
    margin: 3px 4px;
    border-radius: 50%;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-10px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

.table-list-wrapper {
    .table-list-view {
        width: 1280px !important;
        @screen xl {
            width: 100% !important;
        }
    }
}
.xl-overflow-initial {
    @screen xl {
        overflow: initial;
    }
}
.lg-overflow-initial {
    @screen lg {
        overflow: initial;
    }
}
.pagination-wrapper {
    > ul {
        @apply whitespace-nowrap overflow-x-auto;
        @screen lg {
            overflow: initial;
            white-space: initial;
        }
    }
}
.breadcrumb-list {
    color: #475467;
    @apply relative text-sm font-medium px-4;
    &:first-child {
        @apply pl-0 cursor-pointer;
    }
    &:not(:last-child)::after {
        // content: url(../../images/chevron-right.svg);
        @apply absolute top-0 bottom-0 my-auto;
        right: -3px;
    }
    &:last-child {
        @apply text-primary;
    }
}
.tab-item {
    @apply w-1/2 text-center cursor-pointer p-3;
    border-bottom: 1px solid #adadad;
    &.active {
        @apply text-primary-brand border-b-2 border-primary-brand;
    }
}
.group-avatar {
    @apply w-10 h-10 flex items-center justify-center rounded-full cursor-pointer;
    border: 2px solid rgba(73, 79, 219, 0.3);
    background-color: #f2f2ff;
    > img {
        @apply w-full h-full object-cover rounded-full;
    }
    &:not(:first-child) {
        margin-left: -6px;
    }
}

// Mobile - Preapre doc flow
.prepare-doc-mobile {
    background-color: #e7e9f3;
}

.btn--primary-mobile {
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.add-field-btn {
    @apply absolute right-4 flex items-center justify-center gap-2 px-6 py-4 bg-white rounded-2xl z-10 cursor-pointer;
    bottom: 96px;
    box-shadow: 0px 2px 62px rgba(155, 155, 162, 0.51);
}
.add-field-box {
    width: 60px;
    height: 60px;
    @apply flex items-center justify-center flex-col p-4 bg-white rounded-md;
    border: 1px dashed #939393;
}
.mobile-text-field {
    color: #475467;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 12px;
    background: #ffffff;
    border: 2px solid #da8326;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &::placeholder {
        color: #475467;
        font-size: 16px;
        font-weight: 500;
    }
}
.mobile-sign-field {
    display: inline-flex;
    padding: 8px 12px;
    background: #ffffff;
    border: 2px solid #bf56d9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
