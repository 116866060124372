.account-and-plan {
    .card-item {
        background-color: #fff;
        border-radius: 6px;
        &--plan {
            background: #fff url(../../images/bg-gradient-ellipse.svg) no-repeat;
            background-position: right top;
            box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
            padding-bottom: 45px;
        }
        &--details {
        }
    }
    .border {
        border: 1px solid #7b8d9e;
        opacity: 0.4;
        border-bottom-width: 1px;
    }
}
