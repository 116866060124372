.popup-dropdown-menu {
    position: absolute;
    bottom: 116px;
    right: 0;
    min-width: 382px;
    max-width: 528px;
    padding: 20px 20px 20px 18px;
    background-color: #ffffff;
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transition: all 300ms;
}
.popup-dropdown-menu::after {
    content: "";
    position: absolute;
    bottom: -45px;
    right: 10px;
    border: 19px solid transparent;
    border-top: 33px solid #fff;
}
.color-black-100 {
    color: #000;
}
.color-primary-new {
    color: #14304a;
}
.color-secondary-new {
    color: #515556;
}
.popup-dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}
.popup-dropdown__search {
    width: 100%;
    height: 38px;
    color: #14304a;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 24px;
    background: #f1f4f8 url("../../images/search-grey.svg") no-repeat left 28px
        center;
    padding-left: 58px;
    border: 1px solid transparent;
    border-radius: 16px;
    transition: all 300ms;
}
.popup-dropdown__search:focus {
    border: 1px solid #595fed;
    box-shadow: 0px 0px 0px 1px rgba(89, 95, 237, 1);
}
.popup-dropdown__search::placeholder {
    color: #b1b4b5;
    font-size: 16px;
    font-weight: 500;
}
