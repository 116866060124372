.tab {
    &__header {
        @apply flex items-center;
        border-bottom: 1px solid rgba(#7b8d9e, 0.3);
        &.no-border {
            border-bottom: none;
        }
    }
    &__list {
        font-size: 15px;
        @apply text-light-grey1 font-medium cursor-pointer;
        padding: 6px 14px;
        background: rgba(211, 220, 227, 0.25);
        border-radius: 5px 5px 0px 0px;
        &.rounded-10 {
            border-radius: 10px 10px 0 0;
        }
        &.active {
            @apply text-primary;
            background: #e3e4fc;
        }
        &:not(:last-child) {
            @apply mr-1;
        }
    }
    &__list1 {
        font-size: 15px;
        @apply text-light-grey1 font-medium cursor-pointer;
        padding: 6px 14px;
        border-radius: 5px 5px 0px 0px;
        &.rounded-10 {
            border-radius: 10px 10px 0 0;
        }
        &.active {
            @apply text-primary;
            background: white;
        }
        &:not(:last-child) {
            @apply mr-1;
        }
    }
    &__list2 {
        font-size: 15px;
        @apply text-light-grey1 font-medium cursor-pointer;
        padding: 6px 14px;
        // border-radius: 5px 5px 0px 0px;
        &.active {
            @apply text-primary;
            color: #293846;
        }
        &:not(:last-child) {
            @apply mr-1;
        }
    }
    &__list3 {
        font-size: 15px;
        @apply  font-bold cursor-pointer;
        padding: 6px 14px;
        background: white;
        border-radius: 5px 5px 0px 0px;
        &.rounded-10 {
            border-radius: 10px 10px 0 0;
        }
        &.active {
            @apply text-primary;
            background: rgba(89, 95, 237, 0.17);
        }
        &:not(:last-child) {
            @apply mr-1;
        }
    }
    &__body {
        // height: 260px;
        min-height: 265px;
        .draw-wrapper,
        .upload-wrapper {
            @apply flex flex-col;
            min-height: 265px;
            // height: 260px;
        }
    }
}
