.loader {
    @apply relative;
    width: 50px;
    height: 50px;
    background: transparent;
    border-top: 3px solid #595fed;
    border-right: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
    z-index: 50;
    &-container {
        @apply absolute w-full h-full flex items-center justify-center top-0 right-0 left-0 bottom-0;
        &--bg {
            // background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(1px);
            z-index: 40;
        }
    }
}
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
