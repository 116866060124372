.signature-audit-table {
  @apply w-full;
  border-collapse: separate;
  border-spacing: 0;
  thead {
    tr {
      th {
        @apply text-left font-normal;
        font-size: 14px;
        color: rgba(53, 68, 90, 0.6);
        padding: 14px 12px;
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #dbe3ea;
      border-radius: 10px;
      background: #ffffff;
      td {
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.004em;
        padding: 12px;
        border-top: 1px solid #dbe3ea;
        border-bottom: 1px solid #dbe3ea;

        &:first-child {
          // width: 250px;
          border-left: 1px solid #dbe3ea;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          word-break: break-all;
        }
        &:nth-child(4) {
          @apply justify-end;
          padding-left: 30px;
        }
        &:last-child {
          border-right: 1px solid #dbe3ea;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}
