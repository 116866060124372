.aside-right-section {
    height: 100%;
    opacity: 0;
    width: 0;
    @apply px-0 pt-3;
    background-color: #fff;
    right: 0;
    border-left: 1px solid rgba(123, 141, 158, 0.2);
    transition: all 300ms ease-out, opacity 300ms ease-out 200ms;
    padding: 0;
    &__card-item {
        padding: 18px 0 18px 18px;
        @apply bg-white;
        border: 1px solid #dee3e8;
        box-shadow: 0px 9px 9px rgba(184, 202, 225, 0.3);
        border-radius: 10px;
    }
    &.open {
        width: 258px;
        opacity: 1;
    }
    .heading {
        border-bottom: 0.5px solid #95a5b5;
    }
}

.filter-header {
    padding: 8px 20px;
    border-bottom: 1px solid #d8dadb;
    & ~ .b-base {
        padding: 16px 20px;
    }
}
.section-group-title {
    font-size: 13px;
    margin-bottom: 10px;
}

.filter-menu {
    position: absolute;
    height: calc(100% - 56px);
    box-shadow: -4px 0 54px 0 rgba(143, 145, 160, 0.4);
}
.timeline-header {
    font-size: 15px;
    line-height: 20px;
    padding-top: 2px;
}
.aside-right-new {
    .people-list:last-child {
        @apply border-b-0 pb-0;
    }
}

.people-list {
    &-wrapper {
        max-height: 0;
        overflow: hidden;
    }
}
.people-list-accordion.show + div {
    max-height: 0px;
    overflow: hidden;
}
.people-list-accordion.show + div + .people-list-wrapper {
    max-height: 1000px;
    overflow: initial;
}
.people-list-accordion__arrow {
    transform: rotate(0);
    transition: all 300ms;
}
.people-list-accordion.show .people-list-accordion__arrow {
    transform: rotate(90deg);
}
