.add-document {
    &-container {
        @apply relative;
        padding: 20px 26px;
        background: linear-gradient(
                270.48deg,
                rgba(210, 231, 250, 0.73) 15.3%,
                rgba(255, 255, 255, 0) 98.23%
            ),
            url(../../images/add-document-section-bg.png) no-repeat;
        border-radius: 10px;
        background-size: 100%;
    }
    &__new-container {
        @apply relative;
        padding: 20px 26px;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            url(../../images/newbanner.jpg) no-repeat;
        border-radius: 10px;
        background-size: cover;
    }
    &__heading {
        @apply w-full;
        @screen lg {
            width: 450px;
        }
    }
    &-click_text {
        color: #ffffff;
        transform: rotate(-32.73deg);
        font-style: oblique;
    }
    &__btn {
        width: 102px;
        padding: 18px 12px 14px;
        background: rgba(255, 255, 255, 0.53);
        border: 1px solid #babbe6;
        box-shadow: 0px 2px 8px 2px rgba(255, 255, 255, 0.55);
        backdrop-filter: blur(3px);
        border-radius: 10px;
        transition: 0.3s ease;
        &__content {
            @apply text-sm text-text-secondary font-semibold text-center;
            line-height: 97%;
        }
        &__new {
            background-color: #ffffff;
            box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
        }
        &__wide {
            padding: 16px 12px 14px;
            width: 107px;
            background-color: #ffffff;
            box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
        }
        &__icon {
            border-radius: 50%;
            background: #f2c94c;
            padding: 4px;
            box-shadow: 0px 5px 9px rgba(36, 33, 33, 0.35);
        }
        &:hover {
            transform: scale(1.05);
        }
    }
    &-dropdown {
        width: 374px;
        top: 80px !important;
        right: 7px !important;
        @apply absolute py-6 px-4;
        background: #f7fbff;
        border: 1px solid rgba(123, 141, 158, 0.3);
        box-shadow: 0px 12px 10px rgba(184, 202, 225, 0.64);
        border-radius: 10px;
        z-index: 20;
        transform: scale(0.7);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        &.open {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
        &__list {
            @apply flex items-center gap-4 p-2 cursor-pointer;
            transition: all 300ms ease-in;
            &:hover,
            &.active {
                background: #ffffff;
                box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                border-radius: 10px;
            }
        }
        &__icon-container {
            width: 75px;
            height: 75px;
            @apply flex items-center justify-center flex-shrink-0 bg-primary;
            border-radius: 10px;
        }
        &::before,
        &::after {
            content: "";
            @apply absolute;
            border: 20px solid transparent;
            top: -40px;
            right: 47px;
            border-bottom-color: #f6fbff;
        }
        &::before {
            border-bottom-color: rgba(123, 141, 158, 0.3);
            top: -41px;
        }
    }
}
