.stepper {
    &-wrapper {
      @apply flex justify-start ;
    }
    &__list {
      @apply flex items-center;
      &:last-child {
        .stepper__line {
          @apply hidden ml-px;
        }
      }
    //   &:not(:last-child) {
    //     @apply mr-8;
    //   }
      &.active {
        .stepper {
          &__count {
            @apply bg-primary-brand text-white;
            border: unset;
          }
          &__title {
            @apply text-primary-brand;
          }
          // &__line {
          //   border-bottom: 1px solid #5F758A;
          // }
          &__line {
            border-bottom: 1px dashed #485D68;

          }
        }
      }
      &.done {
        .stepper {
          &__count {
            @apply text-white;
            background: #27AE60;
            border: unset;
            &__cont {
              @apply hidden;
            }
            &__check-icon {
              @apply block;
            }
          }
          &__line {
            border-bottom: 1px solid #5F758A;

          }
        }
      }
    }
    &__count {
      width: 27px;
      height: 27px;
      font-size: 18px;
      font-family:'Plus Jakarta Sans';
      border: 1px dashed #5F758A ;
      @apply font-bold flex items-center justify-center bg-white rounded-full object-cover w-10 h-10;
      box-shadow: 0px 4px 4px #d9d9d9;
      &__check-icon {
        @apply hidden;
      }
    }
    &__title {
      @apply  font-bold;
      font-size: 15px;
    }
    &__line {
      width: 50px;
      height: 4px;
    //   @apply ml-8;
      border-bottom: 1px dashed #485D68;
    }
  }
 
  .ml_left{
    margin-left: -100px;
}
@media only screen and (max-width:768px) {
    .ml_left{
        margin-left:0px;
    }
  }