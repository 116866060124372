.template-card {
    // width: calc(100% / 4 - 15px);
    @apply bg-white px-4 py-5;
    border: 1px solid #dcdddd;
    box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
    border-radius: 10px;
    &:nth-child(4n){
      margin-right: 0;
    }
    &__pic {
      @apply w-full;
      height: 170px;
      border: 2px solid #b7c6d5;
      border-radius: 5px;
      > img {
        @apply w-full h-full object-contain;
        border-radius: 5px;
      }
      &::after {
        content: "";
        @apply absolute w-6 h-6 opacity-0 invisible;
        background: url(../../images/icon-green-check-circle.svg) no-repeat top right;
        top: 4px;
        right: 4px;
      }
    }
    &__content {
      &__title {
        font-size: 15px;
        @apply font-bold;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
    &__action-btn {
      @apply opacity-0 invisible;
      transition: opacity 300ms ease-out;
    }
    &:hover .template-card__action-btn {
      @apply opacity-100 visible;
    }
    &.active .template-card__pic::after {
      // @apply opacity-100 visible;
    }
  }
  