.modal {
    @apply fixed w-full h-full flex items-center justify-center top-0 left-0 right-0 bottom-0 opacity-0 invisible;
    z-index: 101;
    // display: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.3s;
    &:after {
        content: "";
        @apply fixed w-full h-full top-0 left-0 bottom-0 right-0;
        background-color: rgba(18, 19, 51, 0.9);
        z-index: 10;
        transition: all 0.3s;
        backdrop-filter: blur(4px);
    }

    &__container {
        @apply relative w-full h-full rounded-none bg-white z-30 overflow-y-auto opacity-0 invisible;
        padding: 35px;
        transform: scale(0.7);
        opacity: 0;
        transition: all 0.3s;
        @screen md {
            width: 536px;
            height: initial;
            border-radius: 10px;
            max-height: calc(100% - 40px);
        }
    }
    &.open {
        @apply opacity-100 visible;
        .modal__container {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
    &__heading {
        font-size: 22px;
        @apply font-medium;
    }

    &__sub-heading {
        font-size: 13px;
        @apply text-light-text;
    }
    &__header {
        @apply flex items-start justify-between;
    }
    &__close-icon {
        @apply cursor-pointer;
    }
    &--document-success {
        .modal {
            &__container {
                @screen md {
                    width: 540px;
                }
                padding: 0;
            }
            &__header {
                padding: 30px 30px 0;
            }
            &__body {
                padding: 10px 60px 30px;
            }
            &__footer {
                padding: 20px 80px 40px;
                background-color: #f4faff;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
    &--signature-audit {
        > .modal__container {
            width: 800px;
            padding: 0;
            .modal__header {
                padding: 25px 35px 10px;
                @apply sticky top-0 bg-white z-10;
            }
            .modal__body {
                padding: 5px 35px 20px;
            }
        }
    }

    &--delete-confirmation {
        .modal__container {
            width: 500px;
            padding: 0px;
            .modal__header {
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .modal__body {
                background: rgba(244, 158, 130, 0.1);
                padding: 20px;
                text-align: center;
            }
            .modal__footer {
                background: rgba(244, 158, 130, 0.1);
                padding: 0px 35px 20px 35px;
            }
            .modal__deleteIcon {
                padding: 5px;
                float: right;
            }
        }
    }

    &--confirmation {
        .modal__container {
            width: 500px;
            padding: 0px;
            .modal__header {
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .modal__body {
                background: rgba(210, 219, 231, 0.1);
                padding: 20px;
                text-align: start;
            }
            .modal__footer {
                background: rgba(210, 219, 231, 0.1);
                padding: 0px 35px 20px 35px;
            }
            .modal__deleteIcon {
                padding: 5px;
                float: right;
            }
            .link {
                color: #595fed;
            }
        }
    }

    &--aadhaar-success {
        &::after {
            background-color: #e5edf4;
        }
        .modal__container {
            width: 500px;
            padding: 0px;
            .modal__header {
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .modal__body {
                background: rgba(210, 219, 231, 0.1);
                padding: 20px;
            }
            .modal__footer {
                background: rgba(210, 219, 231, 0.1);
                padding: 0px 35px 20px 35px;
            }
        }
    }

    &--aadhaar-error {
        &::after {
            background-color: #e5edf4;
        }
        .modal__container {
            width: 500px;
            padding: 0px;
            .modal__header {
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .modal__body {
                background: rgba(244, 158, 130, 0.1);
                padding: 20px;
                text-align: center;
            }
            .modal__footer {
                background: rgba(210, 219, 231, 0.1);
                padding: 15px 35px 20px 35px;
            }
        }
    }

    &--two-factor {
        .modal__container {
            width: 764px;
            .link {
                color: #595fed;
            }
        }
    }

    &--view-permission {
        .view-delete-user-list {
            transition: all 300ms;
            &:hover {
                background: rgba(211, 220, 227, 0.25);
            }
            &__content {
                width: calc(100% - 40px);
            }
            &__delete-icon {
                @apply opacity-0 invisible transition-all;
            }
            &:hover & {
                &__delete-icon {
                    @apply opacity-100 visible;
                }
            }
            &:hover .view-delete-user-list__delete-icon {
                @apply opacity-100 visible;
            }
        }
    }
    &--access-code {
        &::after {
            background-color: #d1e3e3;
        }
        .modal__heading {
            font-size: 16px;
            @screen sm {
                font-size: 22px;
            }
        }
        .modal__footer {
            > .btn {
                @apply w-full;
            }
            @screen sm {
                @apply flex justify-end;
                > .btn {
                    width: initial;
                }
            }
        }
    }

    .card {
        border: 0.86px solid rgba(140, 138, 138, 1);
        padding: 10px 10.3px 12px 10.3px;
        border-radius: 8.58px;
        width: 292px;
        height: 240.3px;
        cursor: pointer;

        &:hover {
            background: #ffffff;
            box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
            transition: all 300ms ease-in;
        }

        &--active {
            border: 1.72px solid rgba(63, 71, 255, 1);
        }
    }
}

.signature-color {
    @apply flex items-center gap-2;
    &__list {
        width: 14px;
        height: 14px;
        @apply rounded-full cursor-pointer;
        background-color: #000;
        &--blue {
            background-color: #6c63ff;
        }
        &--grey {
            background-color: #5f758a;
        }
        &.active {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
}

.folder-color {
    @apply flex items-center gap-2;
    &__list {
        width: 14px;
        height: 14px;
        @apply rounded-full cursor-pointer;
        background-color: #000;
        &--sky-blue {
            background-color: #2d9cdb;
        }
        &--yellow {
            background-color: #f2c94c;
        }
        &--green {
            background-color: #27ae60;
        }
        &--orange {
            background-color: #f2994a;
        }
        &--blue {
            background-color: #595fed;
        }
        &.active {
            border: 1px solid #494fdb;
            box-shadow: 0px 4px 4px 0px #00000040;
        }
    }
}

.table-signature {
    @apply w-full;
    tbody {
        tr {
            td {
                @apply relative break-all cursor-pointer;
                border: 1px solid #d2e7fa;
                padding: 25px 20px;
                transition: all 300ms ease-out;
                &:hover {
                    box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                }
                &.active {
                    &::after {
                        content: "";
                        @apply w-full h-full absolute top-0 left-0 bg-white border border-primary;
                        box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                        z-index: -1;
                        transition: all 300ms ease-out;
                    }
                }
            }
        }
    }
}

.signature-file-upload {
    background: rgba(255, 255, 255, 0.71);
    border: 1px dashed #595fed;
    border-radius: 10px;
    padding: 20px;
    .file-upload {
        &-wrapper {
            width: initial;
            height: initial;
            margin: 0;
            padding: 0;
        }
        &__input {
            @apply hidden;
        }
        &__label {
            @apply block;
            padding: 8px 12px;
            background: #f1f6fa;
            border: 1px solid #dbe3ea;
            border-radius: 10px;
        }
    }
}

.view-file-upload-container {
    @apply flex items-center justify-center bg-white;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
    > img {
        @apply w-full h-full object-contain;
    }
}

.modal--signature-audit {
    .modal--add-signature {
        display: none;
    }
}

.hr-sect {
    overflow: hidden;
    text-align: center;
    color: #c2c7cb;
}
.hr-sect:before,
.hr-sect:after {
    background-color: #c2c7cb;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
.hr-sect:before {
    right: 2em;
    margin-left: -50%;
}
.hr-sect:after {
    left: 2em;
    margin-right: -50%;
}
.modal--mobile {
    width: 100%;
    height: initial;
    position: absolute;
    bottom: 0;
    top: initial;
    align-items: flex-end;
    &:after {
        content: none;
    }
    .modal__container {
        width: 100%;
        max-height: 0;
        height: initial;
        padding: 0;
        background-color: #fff;
        box-shadow: 0px -5px 10px #efefef;
        border-radius: 20px 20px 0px 0px;
        transform: scale(1);
        transform: translateY(100%);
        transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    }
    &.open {
        .modal__container {
            max-height: 92vh;
            transform: translateY(0%);
        }
    }
    .input-field {
        height: 44px;
        padding: 10px 14px;
        background-color: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        &:focus {
            border: 1px solid #d6bbfb;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
                0px 0px 0px 4px rgba(158, 119, 237, 0.24);
        }
    }
    .custom-select {
        background-color: #ffffff;
        border: 1px solid #d0d5dd;
        &:focus {
            border: 1px solid #d6bbfb;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
                0px 0px 0px 4px rgba(158, 119, 237, 0.24);
        }
    }
}
