.sign-signaturebox {
    &__container {
        @apply flex items-center justify-center text-sm;
        color: #253545;
        padding: 2px;
        // min-width: 76px;
        // min-height: 45px;
        background: #ffefcc;
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 300ms ease-in;
        outline: none;
        &.active {
            border: 2px solid #f1bc1a;
        }
    }
}
