// .authentication-section {
//   @apply w-full h-screen flex items-start justify-between bg-white;
//   background: url(../../images/authenticatoin-page-bg.svg) no-repeat;
//   background-size: 100%;
//   padding: 0px;
//   @screen lg {
//     padding: 60px;
//   }
//   &__left {
//     @apply hidden;
//     @screen lg {
//       width: 70%;
//       @apply pr-10 block;
//     }
//   }
// }
// .login-container {
//   width: 100%;
//   min-width: initial;
//   @apply w-full flex items-center justify-center flex-col bg-white m-auto;
//   padding: 35px;
//   border-radius: 10px;
//   &--otp-verification {
//     padding: 38px 44px 70px 44px;
//   }
//   @screen lg {
//     min-width: 440px;
//     width: 30%;
//   }
// }

.authentication-section {
  width: 100%;
  min-height: 100vh;
  background: none;
  background-color: #EDF2F6;
  @screen lg {
    width: 100%;
    min-height: 100vh;
    background: #fff url(../../images/auth-flow-bg.png) no-repeat center;
    background-size: 100%;
  }
  &__container {
    @apply flex items-start justify-between;
    max-width: 100%;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0;
    @screen md {
      @apply flex items-center justify-center;
      max-width: 1220px;
      min-height: 100vh;
      padding: 3% 20px;
    }
  }
  &__left {
    @apply hidden;
    padding-right: 80px;
    &__heading {
      font-size: 31px;
      font-weight: 700;
      color: #253545;
    }
    &__desc {
      font-size: 17px;
      color: #5F758A;
      margin-top: 10px;
    }
    &__pic {
      margin-top: 60px;
    }
    @screen md {
      @apply block;
    }
  }
}
.login-container {
  width: 100%;
  min-height: 100vh;
  flex: initial;
  padding-top: 50px;
  @screen sm {
    width: 430px;
    flex-shrink: 0;
    padding: 30px 35px;
    background: #fff;
    border-radius: 10px;
    min-height: initial;
  }
}

.horizontal-border {
  @apply w-full flex items-center justify-center;
  border-bottom: 1px solid #7b8d9e;
  > span {
    @apply relative z-10;
    top: 12px;
  }
}
