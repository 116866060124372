.template-form {
    border: 1px solid #acbcb4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    width: 500px;
    padding: 40px;
    max-height: 800px;

    .form {
        overflow: auto;
        max-height: 500px;
    }
}

.mediator {
    &::after {
        content: "";
        border-bottom: 1px solid #acbcb4;
    }
}

@media only screen and (max-width: 768px) {
    .template-form {
        width: 400px;
    }
}
