.ripple-wrapper .ripple {
  position: absolute;
  width: 28px;
  height: 28px;
  left: -12px;
  top: -12px;
  opacity: 0;
  border-radius: 28px;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
  background-color: #eb5757;
  &:nth-child(1) {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    background: rgba(235, 87, 87, 0.39);
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    background: rgba(235, 87, 87, 0.23);
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
    background: rgba(235, 87, 87, 0.19);
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
