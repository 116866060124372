.modal {
  &-mobile {
    @apply fixed left-0 bottom-0 right-0 overflow-hidden z-10;
    &:after {
      content: "";
      @apply fixed w-full h-full top-0 left-0 bottom-0 right-0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10;
    }
    &__container {
      @apply relative w-full h-full rounded-none bg-white z-20 overflow-y-auto opacity-0 invisible;
      transform: translateY(200%);
      border-radius: 20px 20px 0px 0px;
      box-shadow: 0px 4px 4px #bcc8d2;
      transition: all 0.3s;
    }
    &__heading {
      @apply text-sm;
    }
    &.open .modal-mobile__container {
      @apply opacity-100 visible;
      transform: translateY(0);
    }
  }
}
