.dot-indicator {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  &--orange {
    background-color: #F2994A;
  }
  &--green {
    background-color: #27AE60;
  }
  &--grey {
    background-color: #4F4F4F;
  }
  &--blue {
    background-color: #2D9CDB;
  }
  &--yellow {
    background-color: #F2C94C;
  }
  &--red {
    background-color: #EB5757;
  }
}