.doc-custom-checkbox {
    &--xs {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1.5px solid #dbe3ea;
        &::after {
            top: 1px;
            right: 0;
        }
    }
    &:checked {
        @apply bg-white;
        border: 1.5px solid #494fdb;
        &::after {
            @apply opacity-100 visible;
        }
    }
}
