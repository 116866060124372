.profile-navigation {
    display: inline-block;
    &__list {
        font-size: 15px;
        @apply font-medium bg-transparent cursor-pointer;
        padding: 8px 14px;
        transition: all 300ms ease-in-out;
        &:hover,
        &.active {
            @apply text-primary-brand;
            background: #e3e4fc;
            border-radius: 6px;
        }
        &:not(:last-child) {
            @apply mb-4;
        }
    }
}
.profile-details {
    width: 271px;

    &__header {
        @apply flex items-center justify-between px-5 py-3 border-b border-grey-500;
    }
    &__body {
        @apply items-start gap-12;

        &__userInfo {
            background-image: url("../../images/Profile.svg");
            padding: 22px 18px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &__organization {
            background: #ffffff;
            padding: 22px 18px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .signature-container {
            height: 58px;
            line-height: 58px;
            background: #f5f5f5;
            border-radius: 10px;
            > img {
                @apply w-full h-full object-contain;
            }
        }
        .input-field {
            width: initial;
            // width: 70%;
        }
    }
}

.avatar-wrapper {
    border-radius: 100%;
    &-pic {
        @apply w-full h-full overflow-hidden;
        border-radius: 100%;
    }
}

.profile-change-password {
    width: 460px;
    background: #ffffff;
    border-radius: 10px;
}
