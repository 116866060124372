.add-signee {
    &-field-container {
        @apply relative bg-white;
        border: 1px solid #dbe3ea;
        border-radius: 10px;
        box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
        &::after {
            content: "";
            @apply absolute w-full h-full left-0 top-0;
            width: 100%;
            height: 100%;
            background-color: #c2cddc;
            filter: blur(100px);
            z-index: -1;
            opacity: 0.3;
        }
    }
    &__card-list {
        @apply relative mb-5;

        &--maintain-order {
            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                left: 12px;
                top: 66%;
                background-color: #5f758a;
            }
            &:last-child::after {
                @apply hidden;
            }
            &::before {
                content: "";
                position: absolute;
                width: 14px;
                height: 1px;
                left: 21px;
                top: 50%;
                background-color: #5f758a;
                // z-index: -1;
            }
        }

        &--sm {
            &::before {
                left: 12px;
                top: 50%;
            }
            &::after {
                left: 10px;
                top: 65%;
            }
            .add-signee {
                &__card-container {
                    // width: calc(100% - 32px);
                    padding: 10px 8px;
                }
                &__count {
                    @apply w-5 h-5;
                    font-size: 13px;
                    line-height: 20px;
                }
                &__remove-btn {
                    // @apply hidden;
                }
                &__details-container {
                    width: 130px;
                }
            }
        }
    }
    &__card-container {
        padding: 12px;
        background: #ffffff;
        border: 1px solid #bcc8d2;
        box-shadow: 0px 9px 9px rgba(184, 202, 225, 0.3);
        border-radius: 10px;
    }
    &__count {
        @apply flex items-center justify-center flex-shrink-0 w-6 h-6 text-white bg-primary font-bold;
        font-size: 15px;
        border: 1px solid #595fed;
        border-radius: 3px;
    }
    &__avatar-wrapper {
        @apply flex items-center justify-center flex-shrink-0;
        width: 34px;
        height: 34px;
        background: rgba(236, 197, 80, 0.49);
        border-radius: 100%;
        .avatar-text {
            font-weight: bold;
            font-size: 13px;
            text-align: center;
            color: #e99a00;
        }
    }
}

.no-data-wrapper {
    @apply relative;
    &::after {
        content: "";
        @apply absolute w-full h-full left-0 top-0;
        width: 100%;
        height: 100%;
        background-color: #a7b3c4;
        filter: blur(100px);
        z-index: -1;
        opacity: 0.3;
    }
}

.greendiv{
    background: #CDFAE0;
border-radius: 5px;
padding: 3px 10px;
}

.greenText {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #27ae60;
}
