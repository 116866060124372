.custom-checkbox {
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    @apply relative bg-white border-2 border-black bg-transparent cursor-pointer;
    transition: all 300ms ease-out;
    border-color: #3b3a3d;
    &::after {
        content: "";
        width: 14px;
        height: 10px;
        @apply absolute opacity-0 invisible;
        background: url(../../images/icon-checkbox-circle.svg) no-repeat center;
        top: 3px;
        right: 1px;
    }
    &--lg {
        &::after {
            width: 90%;
            height: 90%;
            background: url(../../images/icon-checkbox-circle.svg) no-repeat
                center;
            background-size: 90%;
        }
    }
    &:checked {
        @apply bg-primary;
        border: 2px solid transparent;
        &::after {
            @apply opacity-100 visible;
        }
    }
    &--sm {
        width: 18px;
        height: 18px;
        &::after {
            top: 2px;
            right: 0;
        }
    }
    &--xs {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #3b3a3d;
        &::after {
            top: 1px;
            right: 0;
        }
    }
    &--default {
        &::after {
            background: url(../../images/icon-checkbox-circle-black.svg)
                no-repeat center;
        }
        &:checked {
            @apply bg-white;
            border: 2px solid #3b3a3d;
            &::after {
                @apply opacity-100 visible;
            }
        }
    }
}
