.toggle-switch-button {
    @apply relative;
    &__label {
        @apply relative flex items-center justify-center bg-white border border-solid border-primary-brand rounded-2xl cursor-pointer;
        &::after {
            content: "";
            @apply absolute left-0 top-0 w-6/12 h-full bg-primary-brand rounded-2xl;
            transition: all 300ms;
        }
    }
    &__checkbox {
        @apply opacity-0 hidden;
        &:checked {
            & + label {
                &::after {
                    left: 50%;
                }
                .toggle-switch-button__item {
                    &:first-of-type {
                        @apply text-primary-brand;
                    }
                    &:last-of-type {
                        @apply text-white;
                    }
                }
            }
        }
    }
    &__item {
        @apply w-6/12 text-sm font-medium;
        padding: 5px 20px;
        z-index: 1;
        transition: color 300ms 100ms;
        &:first-of-type {
            @apply text-white;
        }
        &:last-of-type {
            @apply text-primary-brand;
        }
    }
}
