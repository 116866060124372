@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/npm/plusplusjakartasans@1.0.0/plusjakartasans.min.css");
@import "./assets/style.scss";
@import "react-datepicker/dist/react-datepicker.css";
// @import url('https://fonts.cdnfonts.com/css/general-sans');
@import url("./assets//sass//general-sans.css");
html,
body {
    @apply text-text-primary scroll-0;
    font-size: 15px;
    font-weight: 500;
    font-family: "general sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e4ecf4;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.font-bold {
    font-weight: 600;
}
.font-light {
    font-weight: 400;
}
