.notification {
  &-list {
    @apply flex items-center px-5 py-4 bg-white mb-2;
    box-shadow: 0px 4px 10px rgba(188, 184, 184, 0.25);
    border-radius: 10px;
    &__msg-content {
      font-size: 15px;
      color: #253545;
    }
    &__time {
      @apply text-xs text-theme-light-grey;
    }
    .dropdown-menu {
      padding: 4px 0;
      @apply bg-white opacity-0 invisible;
      transform: translateY(25px);
      transition: all 300ms ease-in;
      &__list:hover {
        background: rgba(89, 95, 237, 0.22);
        box-shadow: none;
      }
      &.open {
        @apply opacity-100 visible;
        transform: translateY(0);
      }
    }
    &.active {
      background: #f7f7f7;
      border: 1px solid #d6d6d6;
      .notification-list__check-circle {
         path {
          fill: blue;
         }
      }
    }
  }
}
