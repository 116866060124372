.document-timeline {
    &__header {
        @apply flex justify-between;
        padding: 28px;
        background: #fff;
        border: 1px solid #c7d1db;
        border-radius: 10px;
        &__list {
            @apply flex flex-col gap-1;
        }
        .item {
            &-heading {
                @apply text-sm text-light-grey2 font-medium;
            }
            &-desc {
                @apply relative text-text-secondary;
                font-size: 15px;
                @apply font-medium;
            }
            &-status {
                &::after {
                    content: "";
                    @apply absolute w-3 h-3 rounded-full bg-grey-300;
                    top: 5px;
                    left: -20px;
                }
                &--green::after {
                    background-color: #27ae60;
                }
                &--red::after {
                    background-color: #eb5757;
                }
                &--gray::after {
                    background-color: #4f4f4f;
                }
                &--yellow::after {
                    background-color: #f2c94c;
                }
                &--orange::after {
                    background-color: #f2994a;
                }
                &--blue::after {
                    background-color: #2d9cdb;
                }
            }
        }
    }
    &__table {
        &__header {
            @apply flex items-center;
            padding-left: 30px;
            &__items {
                width: 20%;
                font-size: 15px;
                color: rgba(53, 68, 90, 0.6);
                &:last-child {
                    @apply flex-1;
                }
            }
        }
        &__body {
            &__items {
                // @apply text-sm;
                font-size: 14px;
                width: 20%;
                &:first-child::after {
                    content: "";
                    width: 20px;
                    height: 1px;
                    background-color: #cbced2;
                    position: absolute;
                    left: -20px;
                    // top: calc(50% - 1px);
                    top: 12px;
                }
                &:last-child {
                    @apply flex-1;
                }
                &-wrapper {
                    @apply px-2 flex items-center;
                    // @apply py-2 px-8 flex items-center;
                    // background-color: #fff;
                    // border: 1px solid #dbe3ea;
                    // border-radius: 10px;
                    transition: all 300ms ease-out;
                    // &:hover,
                    // &.active {
                    //     box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                    // }
                }
                &-container {
                    @apply relative pb-2;
                    &::after {
                        content: "";
                        @apply absolute left-0 top-0 w-3 h-3 rounded-full bg-light-orange;
                        // top: calc(50% - 6px);
                        top: 6px;
                        left: -30px;
                    }
                    &.status-rejected::after {
                        @apply bg-red;
                    }
                    // &:not(:last-child)::before {
                    //     content: "";
                    //     @apply absolute left-0 top-0;
                    //     width: 1px;
                    //     height: 100%;
                    //     top: 50%;
                    //     left: -24px;
                    //     background-color: #cbced2;
                    //     z-index: -1;
                    // }
                    &::before {
                        content: "";
                        @apply absolute left-0 top-0;
                        width: 1px;
                        height: 100%;
                        top: 0;
                        left: -24px;
                        background-color: #cbced2;
                        z-index: -1;
                    }
                    &:first-child::before {
                        top: 50%;
                        // height: 50%;
                    }
                    &:last-child::before {
                        top: 0;
                        height: 50%;
                    }
                    &.no-border::before {
                        content: none;
                    }
                }
            }
        }
        &--workflow {
            .document-timeline__table__header {
                &__items {
                    @apply w-4/12;
                }
            }
            .document-timeline__table__body {
                &__items {
                    @apply w-4/12;
                }
            }
        }
    }
    .document-thumbnail-carousel {
        @apply relative pr-4;
        max-width: 100%;
        overflow: hidden;
        &__list {
            @apply pr-4 cursor-pointer;
            &-wrapper {
                @apply flex items-center pb-3 overflow-auto;
                border-bottom: 1px solid rgba(#7b8d9e, 0.3);
                /* total width */
                &::-webkit-scrollbar {
                    width: 8px;
                    height: 5px;
                }

                /* background of the scrollbar except button or resizer */
                &::-webkit-scrollbar-track {
                    background-color: rgba(#7b8d9e, 0.3);
                }

                /* scrollbar itself */
                &::-webkit-scrollbar-thumb {
                    background-color: #acabab;
                    border-radius: 8px;
                    width: 8px;
                    height: 8px;
                }
                &::-webkit-scrollbar-button {
                    display: none;
                }
            }
            &.active {
                .document-thumbnail-pic-wrapper {
                    border: 2px solid #595fed;
                }
            }
        }
        .document-thumbnail-pic {
            @apply w-full h-full object-cover;
            border-radius: 5px;
            &-wrapper {
                width: 62px;
                height: 67px;
                padding: 2px;
                border-radius: 5px;
                border: 1px solid #e6e3dc;
            }
        }
        .icon-arrow-right {
            @apply absolute top-0 bottom-0 m-auto cursor-pointer;
            right: -8px;
        }
    }
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* truncate to 2 lines */
    -webkit-line-clamp: 2;
}

.verticalLine {
    position: absolute;
    height: 100%;
    width: 1px;
    border-left: 1px solid #cbced2;
    left: -25px;
    top: 17px;
}
.divider {
    background: #000;
    border: 0;
    color: #000;
    display: inline-block;
    height: 40px;
    width: 1px;
}

.table-row {
    &.active {
        border: 4px solid #595fed;
    }
}


.document-timeline-view{
    margin-bottom:30px;
}
.doc-basic-details{
     .document-timeline-header__list{
        display: flex;
     }
     h5{
        flex-basis: 40%;
     }
     p{
        flex-basis: 60%;
     }
}
.document-timeline-view{
    background: white;
    padding:30px;
    border-radius: 6px;
    padding-bottom: 20px;
}
.document-timeline__header{
    padding: 0;
    border:0;
}
.doc-basic-details{
    flex-basis: 50%;
}
.doc-other-info{
    flex-basis: 50%;
    h4{
        flex-basis: 30%;
     }
     p{
        flex-basis: 70%;
     }
}
.document-timeline-header__list{
    margin: 2px 0;
}
.doc-other-info{
    div{
        margin:2px 0;
    }
    .flex:nth-child(2){
        margin-top: 20px;
    }
}
.document-actions{
    border-top: 1px solid #dbe3ea;
}
.document-timeline__table{

}
.document-timeline__body {
    padding: 60px 20px  20px 40px;
    max-height: calc(100vh - 100px);
}
.aside-right-section{
    .header{
        padding: 8px 20px;
        border-bottom: 1px solid #dbdbdb;
        position: absolute;
        // width: 100%;
        width: 298px;
        background: white;
        // z-index: 2;

    }
}

.back-wrapper{
    svg{
        display: inline-block;
    }
}
.document-timeline__table__body{
    font-weight:600;
    span{
        font-weight: 500;
    }
}
.download-timeline{
    position:absolute;
    top:68px;
    right:20px;
    z-index: 0;
}
.template-details{
    padding:30px;
    background: white;
    border-radius:6px;

    .document-timeline-header__list{
        display: flex;
        flex-direction:row;
        h5{
            flex-basis: 200px;
        }
    }
    .bottom-actions{
        border-top: 1px solid #dbdbdb;
    }
}
.edit-icon{
    display:inline-block;
    margin-Left:10px;
    width: 20px;
    height: 20px;
}