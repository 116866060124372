.secondary-header {
    @apply relative flex items-center;
    // border-bottom: 1px solid rgba(123, 141, 158, 0.3);
    &.secondary-header1 {
        // z-index: -1;
    }
    &__nav {
        @apply flex items-center;
        &__item {
            
            @apply font-medium cursor-pointer;
            font-size: 15px;
            padding: 8px 15px;
            transition: all 300ms linear;
            background-color:rgba(255,255,255,0.6);
            border-radius: 6px;
            transition: all0.3s ease;
            color: #212223;
            &:not(:last-child) {
                                margin-right: 12px;
                            }
            
            &.active {
                @apply text-primary;
                background-color:rgba(255,255,255,1);
                box-shadow: 0 4px 10px 0px rgba(188,184,184,0.28);
                border-radius: 6px;
                font-weight: 600;
                .nav-list__icon path{
                    fill: #494FDB;
                }
                .nav-list__icon--stroke{
                    path{
                        stroke: #494FDB;

                    }
                }
                // svg path{
                //     fill:#494FDB;
                // }
            
            }
            &:hover{
                @apply text-primary;
                // svg path{
                //     fill:#494FDB;
                // }
                .nav-list__icon path{
                    fill: #494FDB;
                }
                .nav-list__icon--stroke  {
                    path {
                    stroke: #494FDB;
                    }
                }

                background-color:rgba(255,255,255,0.8);
            

            }
            svg{
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}
