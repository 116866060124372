.pricing-page {
    &__container {
        @apply relative;
        width: 1180px;
        margin: 0 auto;
        // .plans-list {
        //     @apply flex flex-col w-full p-10;
        //     border-radius: 40px;
        //     &--theme-dark {
        //         @apply text-white bg-primary-brand;
        //     }
        //     &--theme-light {
        //         @apply text-text-primary;
        //         background: #f4f6f8;
        //     }
        // }
        // .plans-features-list {
        //     @apply font-medium;
        //     background: url(../../images/icon-tick-mark.svg) no-repeat top 3px
        //         left;
        //     padding-left: 42px;
        //     background-size: 18px;
        //     &:not(:last-child) {
        //         @apply mb-4;
        //     }
        //     &-wrapper {
        //         @apply relative;
        //         // max-height: 250px;
        //         // overflow: hidden;
        //     }
        // }
    }
    &__close-btn {
        @apply absolute top-0 right-0 h-4 cursor-pointer opacity-50 transition-all;
        &:hover {
            transform: scale(1.1);
            @apply opacity-90;
        }
    }
}

.plans-list {
    @apply relative w-4/12 px-5;
    width: calc(33% -  30px);
    padding: 54px 22px;
    background: #ffffff;
    // backdrop-filter: blur(1.8291px);
    border-radius: 06px;
    transition: box-shadow 300ms;
    cursor: pointer;
    &:hover,
    &.active {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
    }
    &__header {
        @apply pb-5 px-2;
        border-bottom: 4px solid #e3e5ef;

        .btn {
            @apply text-sm font-medium text-primary-brand bg-white;
            padding: 13px 45px;
            border: 1px solid #6c63ff;
            border-radius: 06px;
            font-weight: 600;
            &:hover {
                @apply text-white bg-tertiary-brand;
                border: 1px solid #6c63ff;
            }
        }
        .btn--primary {
            @apply text-sm font-medium text-white;
            padding: 13px 45px;
            background: #6c63ff;
            border: 1px solid #6c63ff;
            border-radius: 06px;
            font-weight: 600;
            &:hover {
                @apply text-white;
                background-color: #4146d1;
            }
        }
    }
    .price-amount {
        color: #2e363e;
        font-size: 52px;
        @apply font-bold;
    }
    .discount-amount {
        color: #d9d9d9;
        font-size: 24px;
        @apply font-bold line-through ml-2;
    }
    &__body {
        @apply px-8;
    }
    &-wrapper {
        @apply -mx-5;
    }
    .label-popular-plan {
        @apply flex items-center justify-center absolute top-0 right-0 left-0 mx-auto text-black font-medium;
        width: max-content;
        padding: 4px 45px;
        background: #fdda7f;
        border-radius: 0px 0px 10px 10px;
    }
    &:first-child .plans-features-wrapper {
        margin-top: 20px;
    }
    .swiper {
        overflow: initial;
    }
    .swiper-pagination-bullets {
        bottom: -50px !important;
    }
    .swiper-pagination-bullet {
        width: 7px;
        height: 7px;
        @apply border border-solid border-tertiary-brand bg-transparent;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            @apply bg-tertiary-brand;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 20px !important;
        height: 20px !important;
        bottom: -51px !important;
        left: 0;
        right: 0;
        top: initial;
        width: auto;
        height: auto;
        margin-top: 0 !important;
        z-index: 20;
        cursor: pointer;
        &::after {
            font-size: 12px !important;
            font-weight: 900;
        }
    }
    .swiper-button-prev {
        left: 85px;
    }
    .swiper-button-next {
        left: initial;
        right: 85px;
    }
}

.plans-features {
    &-wrapper {
        @apply mt-8;
        // max-height: 300px;
    }
    &-list {
        @apply flex items-center text-secondary-text text-sm font-medium;
        &:not(:first-child) {
            margin-top: 14px;
        }
        &__icon {
            max-width: 25px;
            max-height: 25px;
            @apply flex-shrink-0 mr-5 object-contain;
        }
    }
}

.section-enterprisers {
    padding: 30px;
    @apply w-10/12 bg-white;
    width:calc(100% - 30px);
    border-radius: 6px;
    margin: 120px auto;
    &__feature-list {
        @apply w-4/12 flex text-sm font-medium text-secondary-text mb-6;
        &-wrapper {
            @apply flex flex-wrap mt-5;
        }
        &__icon {
            max-width: 23px;
            max-height: 23px;
            @apply flex-shrink-0 object-contain mr-5;
        }
    }
    h4{
        font-size:20px;
        margin-bottom: 10px;
    }
    
    
}

.slider-page-count {
    color: #ffffff;
    font-size: 11px;
    font-weight: 500;
    padding: 3px 18px;
    background: #afafaf;
    border-radius: 18px;
}
.plans-list-wrapper{
    position: relative;
    top:30px;
    justify-content: center;
    margin-left: 0;
    .plans-list:nth-child(2){
        transform: translateZ(0);
        perspective: 1px;
        transform: scale(1.1);
        
    }
}
.pricing-page{
    position: fixed;
    background-color: rgba(18,19,51,0.85);
    backdrop-filter: blur(4px);
    padding:60px 20px;
    top:0;
    left:0;
    z-index: 1000;
    width:100%;
    height: 100%;
    overflow-y: auto;
    h1{
        color:white;
        font-weight: 600;
    }
}

.upgrade-plan-wrapper{
    
}

.pricing-page__close-btn{
    position:fixed;
    top:40px;
    right:40px;
    z-index: 1001;
    opacity: 1;
    filter: invert(1);
}
.section-enterprisers__feature-list-wrapper{
    max-width: 1000px;
}