.counter {
    @apply border  rounded truncate bg-white cursor-pointer;
    border-color: #bdcad6;
    width: fit-content;
    box-shadow: 0px 13px 13px #e6ebef;
    input {
        width: 30px;
        text-align: center;
    }
    .items {
        @apply flex  items-center justify-center;
        width: 30px;
        &__input {
            border-right: 1px;
            border-left: 1px;
        }
    }
}
