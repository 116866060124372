.btn {
    @apply flex items-center justify-center font-medium bg-white;
    font-size: 15px;
    padding: 8px 16px;
    border: 1px solid #bcc8d2;
    border-radius: 6px;
    transition: all 300ms ease-in;
    font-weight:600;
    outline: none;
    &--pagination {
        &.active {
            background-color: #595fed;
            color: #fff;
            border: 1px solid #595fed;
        }
    }
    &--primary {
        @apply bg-primary text-white;
        border: 1px solid transparent;
        &:hover {
            background-color: #4146d1;
        }
        &:focus {
            background: #4146d1;
            box-shadow: 0px 2px 2px #cdcaf9;
        }
    }
    &--secondary {
        @apply bg-secondary text-black;
        border: 1px solid transparent;
        &:hover {
            background-color: #f1bc1a;
        }
        &:focus {
            background: #f1bc1a;
            box-shadow: 0px 2px 2px rgba(253, 234, 177, 0.67);
        }
    }
    &--black {
        @apply bg-black text-white;
        border: 1px solid transparent;
        &:hover {
            box-shadow: 0px 2px 2px #cdcaf9;
        }
    }
    &--white {
        @apply relative text-primary-brand bg-white;
        border: 1px solid #bcc8d2;
        &:hover {
            background-color: #f4faff;
        }
        &:focus {
            box-shadow: 0px 2px 2px #cdcaf9;
        }
    }
    &--red {
        @apply relative text-white;
        background: #eb5757;
        border: 1px solid #bcc8d2;
        &:hover {
            background-color: #eb5757;
        }
        &:focus {
            box-shadow: 0px 2px 2px #000000;
        }
    }
    &--new-transparent {
        background: unset;
        border: 1px solid #65434370;
        &:hover {
            background-color: #f4faff;
        }
    }
    &--transparent {
        @apply relative text-light-grey2 bg-white;
        border: 0;
    }
    &--lg {
        padding: 11px 22px;
        @apply text-base;
    }
    &--sm {
        padding: 6px 16px;
    }
    &--newsm {
        padding: 6px 6px;
        display: inline-block;
    }
    &--xs {
        padding: 2px 16px;
    }
    &--disable {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
    }
    &--notdisable {
        pointer-events: none;
        cursor: not-allowed;
    }
}

.button-skeleton {
    background-color: #e0e0e0; /* Use a darker background color */
    opacity: 0.7; /* Increase the opacity */
    cursor: default;
    pointer-events: none;
    position: relative;
    overflow: hidden;
  }
  
  .button-skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.3), /* Increase the opacity of the gradient colors */
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.3)
    );
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    100% {
      left: 100%;
    }
  }
  
  
  
  
  
  
  
   
