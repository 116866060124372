.download-dropdown-menu {
    @apply absolute opacity-0 invisible;
    width: 338px;
    border: 1px solid rgba(123, 141, 158, 0.3);
    box-shadow: 0px 2px 2px rgba(184, 202, 225, 0.64);
    border-radius: 10px;
    background: #f7fbff;
    transition: all 300ms ease-out;
    z-index: 10;
    &.open {
        @apply opacity-100 visible;
    }
    &.transform-animation {
        transform: translateY(100px);
        transition: all 300ms ease-out;

        &.open {
            transform: translateY(0);
        }
    }
    &__list {
        @apply flex items-center gap-4 p-2;
        transition: all 300ms ease-in;
        &:hover,
        &.active {
            background: #fff;
            box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
            border-radius: 10px;
        }
        &__icon-container {
            width: 75px;
            height: 75px;
            @apply flex items-center justify-center flex-shrink-0 bg-primary;
            border-radius: 10px;
        }
        &--full {
            &:hover {
                @apply p-2;
                box-shadow: none;
                background: rgba(89, 95, 237, 0.22);
                transition: all 300ms ease-in;
                border-radius: 0;
            }
        }
    }
}

.dropdown-menu-label {
    opacity: 0.75;
    transition: all 300ms;
    &:hover {
        opacity: 1;
    }
}

.dropdown-menu {
    &--draggable-com {
        @apply p-4;
        border: 1px solid #c2cddc;
        box-shadow: 0px 12px 50px rgba(118, 143, 176, 0.53);
        border-radius: 9px;
        &__list {
            @apply flex items-center justify-between;
            padding: 10px 0;
            border-bottom: 1px solid #cdd7e0;
            &__label {
                @apply text-sm;
            }
        }
    }
}
