.mobile-document-list {
  @apply p-5 bg-white my-2;
  border-radius: 10px;
  &__thumbnail {
    width: 53px;
    height: 58px;
    border: 2px solid #b7c6d5;
    border-radius: 5px;
    > * {
      @apply w-full h-full;
    }
  }
}
