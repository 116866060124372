.signee-card-list {
  padding: 10px;
  background: #ffffff;
  border: 1px solid #bcc8d2;
  box-shadow: 0px 9px 9px rgba(184, 202, 225, 0.3);
  border-radius: 10px;
  flex: 1;
  &__avatar-wrapper {
    @apply flex flex-shrink-0 items-center justify-center;
    width: 30px;
    height: 30px;
    background: rgba(236, 197, 80, 0.49);
    border-radius: 100%;
    .avatar-text {
      font-weight: bold;
      font-size: 13px;
      text-align: center;
      color: #e99a00;
    }
  }
}
