.accordion {
    &__item {
        background: #ffffff;
        border-bottom: 1px solid #c2cddc;
        // box-shadow: 0px 12px 50px rgba(118, 143, 176, 0.53);
        border-radius: 0;
    }
    &__new-item {
        background: #f9fbff;
        border-radius: 10px;
        border-bottom: 1px solid #c2cddc;
    }
    &__header {
        @apply flex items-center p-4 cursor-pointer;
        &__box-indicator {
            @apply w-4 h-4;
            background: #27ae60;
            border-radius: 5px;
            &--yellow {
                background: #f2c94c;
            }
            &--red {
                background: #ffe3e3;
            }
            &--blue {
                background: #3498db;
            }
            &--dark {
                background: #34495e;
            }
            &--purple {
                background: #b2b5ff;
            }
            &--orange {
                background: #fa6c0d;
            }
            &--pink {
                background: hwb(313 6% 2%);
            }
        }
    }
    &__body {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        &__content {
            @apply px-4 pb-5;
        }
    }
    &__header.active {
        .accordion__header__icon {
            transform: rotate(-180deg);
            transition: all 300ms linear;
        }
        + .accordion__body {
            overflow: auto;
            max-height: initial;
        }
    }
}
