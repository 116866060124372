.breadcrumb {
  &__list {
    @apply relative text-xs pr-2;
    color: #35445A;
    opacity: 0.9;
    &:last-child {
      @apply text-primary-brand;
    }
    &:not(:last-child)::after {
      content: "/";
      color: #35445A;
      @apply absolute top-0 right-0;
    }
  }
}