.docStatusCard {
  width: 83px;
  background: #ffffff;
  box-shadow: 0px 4px 4px #dee3e8;
  border-radius: 5px;
  padding: 3px, 4px, 3px, 4px;
}

.docStatusTxt {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.004em;
  margin: 5px 5px;
  color: #000000;
}

.docRejectedStatus {
  background: #eb5757;
  border-radius: 50%;
  width: 12px;
  height: 12px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 20px;
}
