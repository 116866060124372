.send_header{
    @apply mb-3 ;
}
.send_wrapper{
    @apply flex flex-row justify-center;
    &__documentContainer{
        @apply bg-white  rounded-lg px-6 pt-5 pb-5;
        &__subject-input{
          @apply  h-10 border border-solid  rounded-md py-2 px-2
        }
    }
}