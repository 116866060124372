.custom-select {
    &-wrapper {
        position: relative;
    }
    &-container {
        color: var(--grey90);
        font-weight: 500;
        background: var(--new-neutral-white, #fff);
        border: 1px solid var(--grey10);
        border-radius: 4px;
        cursor: pointer;
        &.open {
            .custom-select__arrow-down {
                transform: rotate(180deg);
            }
            + .select-dropdown-menu {
                height: max-content;
                overflow: initial;
                opacity: 1;
                visibility: visible;
                transition: all 300ms;
            }
        }
        &--md {
            padding: 10px 12px;
        }
    }
    &__arrow-down {
        position: absolute;
        right: 16px;
        top: calc(50% - 4px);
        transition: all 300ms;
    }
}
.select-dropdown-menu {
    width: 100%;
    height: 0;
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    background-color: #fff;
    border: 1px solid var(--grey10);
    border-radius: 4px;
    overflow: hidden;
    transition: all 300ms;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    &__list {
        color: var(--grey90);
        font-size: 16px;
        font-weight: 500;
        padding: 8px 12px;
        transition: all 300ms;
        cursor: pointer;
        &:hover {
            background-color: #dbe3ea;
        }
    }
}
