.new-add-signee{
    margin-left: auto;
    margin-right: auto;
    max-width: 67%;
    padding-left: 13px;
    padding-right: 13px;
    &_subheading{
        font-size: 14px;
        color: #253545;
    }
    &_skip-step {
        background: #ffffff;
        padding: 20px;
        box-shadow: 0px 4px 10px rgba(188, 184, 184, 0.25);
        border-radius: 5px;
        border-left: 5px solid #F2C94C;
    }
    &_container {
        background: #ffffff;
        padding: 20px;
        box-shadow: 0px 4px 10px rgba(188, 184, 184, 0.25);
        border-radius: 5px;
    }
    &_or{
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 5%;
        left: 50%;
        width: 34px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        @apply flex items-center justify-center bg-white rounded-full;
        box-shadow: 0px 4px 4px #d9d9d9;
    }
    &_radio {
        transform: scale(1.2);
    }
}
.divider{
    height: 1px;
    margin-top: 14px;
    background: #5F758A;
    opacity: 0.3;
    width: 100%;
}


