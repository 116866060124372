.account-billing {
    .table-billing {
      thead {
        tr {
          background-color: rgba(211, 220, 227, 0.25);
          td {
            @apply text-sm text-light-grey1 py-2;
            &:first-child {
             @apply pl-4;
            }
            &:last-child {
             @apply pr-4 text-right;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #D4DCE6;
        }
        td {
          @apply text-sm font-medium py-3;
          &:first-child {
           @apply pl-4;
          }
          &:last-child {
           @apply pr-1 text-right;
          }
          
        }
      }
    }
   }
   