.toaster {
    @apply fixed bottom-20 right-20 opacity-0 invisible z-50;
    transform: translateY(100%);
    transition: all 500ms cubic-bezier(0.07, 0.53, 0.05, 0.91);
    &.show {
        @apply opacity-100 visible;
        transform: translateY(0);
    }
    &__container {
        @apply flex items-center justify-between gap-5 px-5 py-4 text-white rounded mb-3 opacity-0 invisible;
        background-color: #4f4f4f;
        max-width: 500px;
        transform: translateY(50%);
        transition: all 500ms cubic-bezier(0.07, 0.53, 0.05, 0.91);
        &.info {
            @apply bg-blue;
        }
        &.warning {
            @apply bg-orange;
        }
        &.success {
            @apply bg-green;
        }
        &.danger {
            @apply bg-red;
        }
        &.show {
            @apply opacity-100 visible;
            transform: translateY(0);
        }
        &.no-icon {
            @apply gap-0;
            .toaster__close-icon {
                @apply hidden;
            }
        }
    }
    &__close-icon {
        path {
            stroke: #fff;
        }
    }
}
