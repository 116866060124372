.sign-textbox {
    &__input-field {
        @apply text-sm;
        color: #253545;
        padding: 2px;
        background: rgba(87, 211, 140, 0.38);
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 300ms ease-in;
        outline: none;
        &:focus {
            border: 2px solid #27ae60;
        }
        &::-webkit-input-placeholder {
            color: #253545;
        }
    }
}
