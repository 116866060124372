.aside-section {
    width: 220px;
    height: calc(100vh - 56px);
    @apply fixed px-3 pt-6 opacity-0 z-10;
    background-color: #fff;
    border-right: 1px solid rgba(123, 141, 158, 0.2);
    transform: translateX(-220px);
    transition: all 300ms;

    @screen lg {
        position: initial;
        height: initial;
        transform: none;
        opacity: 1;
    }
    &.is-fixed {
        transform: translateX(0px);
        @apply opacity-100;
        transition: all 300ms;
    }
    .nav-list {
        padding: 8px 10px;
        margin-bottom: 6px;
        border-radius: 10px;
        @apply flex items-center gap-2 cursor-pointer;
        transition: all 300ms ease-in;
        opacity: 0.9;
        &__item {
            font-size: 14px;
            @apply font-medium text-black;
        }
        &.active {
            background: rgba(89, 95, 237, 0.22);
            .nav-list {
                &__item {
                    @apply text-primary-brand font-bold;
                }
                &__icon path {
                    fill: #595fed;
                }
                &__icon--stroke {
                    path {
                        stroke: #595fed;
                    }
                }
            }
        }

        &:hover {
            background: #e9ebfa;

            .nav-list {
                &__item {
                    @apply text-primary-brand;
                }
                &__icon path {
                    fill: #595fed;
                }
                &__icon--stroke {
                    path {
                        stroke: #595fed;
                    }
                }
            }
        }
    }

    .folder {
        cursor: pointer;
        &-container {
            &.open {
                .folder__arrow-down {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .folder-list {
        padding: 8px 10px;
        margin-bottom: 6px;
        border-radius: 10px;
        @apply flex items-center gap-2;
        transition: all 300ms ease-in;
        opacity: 0.9;
        &__item {
            font-size: 14px;
            @apply font-medium text-black;
        }
        &:hover {
            background: #e9ebfa;
        }
        &.active {
            background: rgba(89, 95, 237, 0.22);
        }
    }

    &__footer {
        width: calc(100% + 24px);
        margin-left: -12px;
        padding: 15px 12px;
        border-top: 1px solid rgba(123, 141, 158, 0.4);
    }
    .copyright-text {
        color: rgba(41, 56, 70, 0.8);
        font-size: 13px;
    }
    &--account {
        background-color: white;
        border: 0;
        padding: 20px 20px 40px 20px;
        border-radius: 6px;
        h4 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 6px;
        }
        p {
            font-size: 13px;
            margin-bottom: 30px;
            line-height: 126%;
            color: #4e5457fe;
        }
    }
}
.aside-menu-mobile {
    width: 54px;
    background-color: #fff;
    border-right: 1px solid rgba(123, 141, 158, 0.2);
}
