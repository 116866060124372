.table-list-view {
    border-collapse: separate;
    border-spacing: 0;
    background: rgba(255, 255, 255, 0.8);
    thead {
        tr {
            th {
                @apply text-left font-medium;
                font-size: 15px;
                color: rgba(53, 68, 90, 1);
                padding: 10px 12px;
                white-space: nowrap;
            }
        }
    }
    tbody {
        tr {
            border: 1px solid #dbe3ea;
            background: #ffffff;
            td {
                font-size: 15px;
                padding: 10px 12px;
                border-top: 1px solid #dbe3ea;
                border-bottom: 1px solid #dbe3ea;
                &:first-child {
                    font-weight: 600;
                    // width: 45%;
                    border-left: 1px solid #dbe3ea;
                    > div {
                        word-break: break-all;
                    }
                }
                &:last-child {
                    border-right: 1px solid #dbe3ea;
                }
            }
            // &:first-child {
            //     width: 300px;
            // }
            .options {
                display: none;
            }
            &:hover {
                background: #ffffff;
                box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                transition: all 300ms ease-in;
            }
            &:hover .options {
                display: flex;
            }

            .document-thumbnail {
                height: 38px;
                width: 35px;
                border: 2px solid #b7c6d5;
                border-radius: 5px;
                > {
                    @apply w-full h-full object-contain;
                }
            }
        }
    }
    &--teams {
        border-collapse: collapse !important;
        thead {
            tr {
                th {
                    @apply text-text-primary font-bold;
                }
            }
        }
        tbody {
            tr {
                @apply cursor-pointer;
                &:hover {
                    background: #fff !important;
                    box-shadow: none !important;
                }
            }
            td {
                border: 1px solid #c2cddc;
            }
        }
        .thead-secondary {
            background-color: transparent !important;
            &:hover {
                background-color: transparent !important;
            }
            &:first-child {
                border-left: none !important;
                border-right: none !important;
            }
            td {
                @apply text-xs;
                &:first-child {
                    border-left: none !important;
                }
                &:last-child {
                    border-right: none !important;
                }
            }
        }
    }
}
.table-container {
    width: 100%;
    overflow: auto;
    @screen sm {
        overflow: initial;
    }
    table {
        max-width: 100%;
    }
}

.tableDocName {
    font-size: 16px;
}
