.sign-datebox {
    &__input-field {
        @apply text-sm;
        color: #253545;
        padding: 2px;
        // background: linear-gradient(0deg, #ffe3e3, #ffe3e3), #ffffff;
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 300ms ease-in;
        outline: none;
        width: 100%;
        height: 100%;
        &:focus {
            border: 2px solid #eb5757;
        }
        &::-webkit-input-placeholder {
            color: #253545;
        }
    }
    &__bgColor {
        background: linear-gradient(0deg, #ffe3e3, #ffe3e3), #ffffff;
    }
}

.sign-datebox-signee-prepare {
    &__input-field {
        color: #253545;
        padding: 2px;
        background: linear-gradient(0deg, #ffe3e3, #ffe3e3), #ffffff;
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 300ms ease-in;
        outline: none;
        width: 100%;
        height: 100%;
        font-size: 90%;
        &:focus {
            border: 2px solid #eb5757;
        }
        &::-webkit-input-placeholder {
            color: #253545;
        }
    }
    &__bgColor {
        background: linear-gradient(0deg, #ffe3e3, #ffe3e3), #ffffff;
    }
}
