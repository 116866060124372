.upload-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    padding-left: 13px;
    padding-right: 13px;
    // background: #ffffff;
}
.upload-heading {
    color: #253545;
    // font-family: 'General Sans';
    font-weight: 500;
    font-size: 24px;
    line-height: 17px
}
.upload-header {
    background: #ffffff;
}
.upload-input-container{
    width: 49%;
    margin-left: auto;
    margin-right: auto;
}
.toast-message {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    background: #E1F3DC;
    border-radius: 7px;
    border-left: 5px solid #27AE60;

}
.disable-next-button {
    pointer-events: none;
    opacity: 0.7;
}